import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ButtonClass,
  DropdownOptionObject,
  Icon,
  IconFontSize,
  IconSize
} from '@claas/claas-form-components';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';
import { passedDataToDialog } from '../../dialog-window/dialog-window.component';
import { BehaviorSubject, Subscription, switchMap } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { OrganisationRole } from '../../../models/organisation-role.model';
import { OrganizationInvitation } from '../../../models/organisation-invitation.model';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-admin-invitation-view',
  templateUrl: './admin-invitation-view.component.html',
  styleUrl: './admin-invitation-view.component.scss'
})
export class AdminInvitationViewComponent implements OnInit, OnDestroy {
  @Input() data: passedDataToDialog = {};
  @Input() secondaryTextButtonClass?: ButtonClass;
  @Output() closeDialogWindow = new EventEmitter();
  @Output() displayPopup = new EventEmitter();
  organisationInvitation: OrganizationInvitation | undefined;
  orgName: string = '';
  auth0Id: string = '';
  largeSize: CircularProgressDiameter = CircularProgressDiameter.LARGE;
  mediumIconSize: IconSize = IconSize.MEDIUM;
  secondaryColor: ProgressColor = ProgressColor.SECONDARY;
  selectedRole: string = '';
  mediumIconFontSize = IconFontSize.MEDIUM;
  showSpinner$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  roles = [];
  defaultRole: DropdownOptionObject | undefined;
  infoIcon: Icon = {
    iconId: '001111',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 48
  };
  private subscriptions = new Subscription();

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    if (this.data.organisationInvitation?.auth0Id) {
      this.auth0Id = this.data.organisationInvitation.auth0Id;

      this.subscriptions.add(
        this.authService
          .getTokenSilently$()
          .pipe(
            switchMap((token: string) =>
              this.apiService.getOrganisation(this.auth0Id, token)
            )
          )
          .subscribe({
            next: (result: any) => {
              this.orgName = result.name;
            }
          })
      );
    }

    if (this.data.organisationInvitation) {
      this.organisationInvitation = this.data.organisationInvitation;
    }

    this.getRoles();
  }

  getRoles() {
    this.subscriptions.add(
      this.apiService.getRolesInOrganisation().subscribe({
        next: (result: any) => {
          const transformedRoles = result['content'].map(
            (role: OrganisationRole) => ({
              text: this.translateService.instant(
                role.translationKey.replace('organization_', 'organization.')
              ),
              key: role.role
            })
          );

          this.roles = transformedRoles.filter(
            (r: { text: string; key: string }) => r.key !== 'DEMO_ADMIN'
          );

          this.defaultRole = this.roles[0];

          this.selectedRole = 'ADMIN';
        }
      })
    );
  }

  onChangeRole(e: DropdownOptionObject) {
    this.selectedRole = e as unknown as string;
  }

  onClose(joinOrg: boolean): void {
    const organization = this.organisationInvitation!.auth0Id;

    if (organization) {
      this.authService.getTokenSilently$().subscribe((token: string) => {
        if (joinOrg) {
          // Create member in organization
          const payload = {
            idpUser: this.organisationInvitation!.userId,
            role: this.selectedRole
          };

          this.apiService
            .createOrganisationMember(payload, organization, token)
            .subscribe();
        }

        this.organisationInvitation!.status = joinOrg ? 'accepted' : 'denied';
        this.apiService
          .setOrganisationAdminInvitationStatus(
            this.organisationInvitation!,
            organization,
            token
          )
          //TODO: MAYBE IMPLEMENT AN ERROR STATE?
          .subscribe();

        const passedObject = {
          context: this.data.context,
          action: 'save'
        };

        this.closeDialogWindow.emit(passedObject);
      });
    }
  }

  showRoleAndRightsInfo(): void {
    window.location.href = '';
  }

  getRoleOption(role: any) {
    return {
      text: this.translateService.instant(
        'organization.roles.' + role.text.toLowerCase()
      ),
      key: role
    };
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
