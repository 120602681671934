import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Icon, IconFontSize, IconSize } from '@claas/claas-form-components';
import {
  CircularProgressDiameter,
  ProgressColor
} from '@claas/claas-layout-components';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  finalize,
  forkJoin,
  switchMap,
  tap
} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  selectDefaultOrganisation,
  selectOrganisationsSummary,
  selectRegistrationSteps
} from '../shared/state/organisations/organisations.selectors';
import { filter, map, take } from 'rxjs/operators';
import { UserOrganisationSummary } from '../shared/state/organisations/organisations.state';
import { selectUserDetails } from '../shared/state/user';
import {
  loadRegistrationDefaultOrganisation,
  loadUserDefaultOrganisation,
  loadUserOrganisationsSummary,
  organisationMembersNotLoaded,
  setUserDefaultOrganisation
} from '../shared/state/organisations';
import { ApiService } from '../shared/services/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InvitationCreateModel } from '../shared/models/invitation-create.model';
import { AuthService } from '../shared/services/auth.service';
import { loadOrganisationDefaultDealer } from '../shared/state/dealers';
import { OrganisationShopModel } from '../shared/models/organisation-shop.model';
import { OrganisationDealerModel } from '../shared/models/organisation-dealer.model';
import { IRegistrationDialogStep } from 'cde-fe-organization-registration-dialog/lib/models/organisation.model';
import { OrganisationRegistrationDialogUserModel } from '../shared/models/organisationRegistrationDialogUser.model';
import { IDealerData } from 'cde-fe-organization-registration-dialog/lib/models/dealer.model';

export interface DefaultOrganisation {
  orgId: string;
}

@Component({
  selector: 'app-organisation-data',
  templateUrl: './organisation-data.component.html',
  styleUrls: ['./organisation-data.component.scss']
})
export class OrganisationDataComponent implements OnDestroy {
  private subscriptions = new Subscription();

  tabs: any[] = [];
  currentOrganisation: UserOrganisationSummary | undefined;
  previousIcon: Icon = {
    iconId: '006230',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 19
  };

  starIcon: Icon = {
    iconId: '000983',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 19
  };

  orgId: any;
  currentUser: any;
  currentIndex = 0;

  largeSize = CircularProgressDiameter.LARGE;
  mediumIconSize = IconSize.MEDIUM;
  mediumIconFontSize = IconFontSize.MEDIUM;
  secondaryColor = ProgressColor.SECONDARY;
  isLoadingOrganisationDetails$ = new BehaviorSubject(true);
  organisationData$ = new BehaviorSubject<UserOrganisationSummary | undefined>(
    undefined
  );
  isDefaultOrganisation$ = new BehaviorSubject<boolean>(false);
  currentIndex$ = new BehaviorSubject<any>(0);
  organisationMembers$ = new BehaviorSubject<any[] | undefined>(undefined);
  organisationShops$ = new BehaviorSubject<
    OrganisationShopModel[] | null | undefined
  >(undefined);
  organisationConnectedDealers$ = new BehaviorSubject<
    OrganisationDealerModel[] | null | undefined
  >(undefined);
  isAdmin$ = new BehaviorSubject<boolean>(false);
  withUniqueAdmin$ = new BehaviorSubject<boolean>(false);
  startInvite$ = new BehaviorSubject<boolean>(false);
  myUserId$ = new BehaviorSubject<any>(undefined);
  roleOptions$ = new BehaviorSubject<string[]>([]);
  requestInProgress$ = new BehaviorSubject<boolean>(false);
  currentUser$ = new BehaviorSubject<
    OrganisationRegistrationDialogUserModel | undefined
  >(undefined);
  registrationSteps$ = new BehaviorSubject<
    undefined | IRegistrationDialogStep[] | null
  >(undefined);

  @ViewChild('overview') overview!: TemplateRef<any>;
  @ViewChild('usersManagement') usersManagement!: TemplateRef<any>;
  @ViewChild('dealersManagement') dealersManagement!: TemplateRef<any>;

  constructor(
    private store: Store,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private auth: AuthService,
    private snackBar: MatSnackBar,
    private authService: AuthService
  ) {
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe(params => {
        this.orgId = params['orgId'];
        this.currentIndex =
          params['selectedTab'] === 'members' ? 1 : this.currentIndex;
        this.currentIndex =
          params['selectedTab'] === 'dealer' ? 2 : this.currentIndex;
        if (params['startInvite']) {
          this.startInvite$.next(true);
        }

        if (this.orgId) {
          const organization: string = this.orgId;

          this.subscriptions.add(
            this.store
              .select(selectUserDetails)
              .pipe(
                filter((user: any) => !!user),
                tap((user: OrganisationRegistrationDialogUserModel) => {
                  this.currentUser = user;
                  this.currentUser$.next(this.currentUser);

                  this.authService
                    .getTokenSilently$({ organization, newToken: true })
                    .pipe(take(1))
                    .subscribe((token: string) => {
                      this.store.dispatch(
                        loadUserDefaultOrganisation({ userId: user.uuid })
                      );

                      this.checkDefaultOrganisationState();
                      this.loadOrganisations();
                      this.getRolesInOrganisation();

                      this.getOrganisationMembers(organization);
                      this.getOrganisationShops(organization);
                      this.getOrganisationConnectedDealers(organization);
                    });
                })
              )
              .subscribe()
          );
        }

        this.subscriptions.add(
          this.store
            .select(selectRegistrationSteps)
            .subscribe(steps => this.registrationSteps$.next(steps))
        );
      })
    );
  }

  checkDefaultOrganisationState(): void {
    this.subscriptions.add(
      this.store
        .select(selectDefaultOrganisation)
        .pipe(
          filter((result: any) => !!result),
          tap((orgaId: string) => {
            this.isDefaultOrganisation$.next(orgaId === this.orgId);
          })
        )
        .subscribe()
    );
  }

  loadOrganisations(): void {
    this.subscriptions.add(
      this.store
        .select(selectOrganisationsSummary)
        .subscribe((organisations: UserOrganisationSummary[] | null) => {
          if (!organisations) {
            return this.store.dispatch(loadUserOrganisationsSummary());
          } else {
            // check if the org details has been loaded
            const targetOrg = organisations.find(
              org => org['auth0Id'] === this.orgId
            );
            if (this.orgId && targetOrg) {
              this.currentOrganisation = targetOrg;
              this.isLoadingOrganisationDetails$.next(false);
              this.organisationData$.next(this.currentOrganisation);

              if (this.currentOrganisation['country']) {
                this.store.dispatch(
                  loadRegistrationDefaultOrganisation({
                    country: this.currentOrganisation['country']
                  })
                );
              }

              const preferredDealer: any = this.currentOrganisation
                ? this.currentOrganisation['dealer']
                : undefined;

              if (preferredDealer?.id) {
                this.getOrganisationDefaultDealer(
                  this.orgId,
                  preferredDealer.id
                );
              }

              setTimeout(() => {
                this.setTabs();
              }, 500);
            }
          }
        })
    );
  }

  getRolesInOrganisation(): void {
    this.subscriptions.add(
      this.apiService
        .getRolesInOrganisation()
        .pipe(
          map((result: any) => result.content),
          map((elements: any[]) => {
            const roles: any[] = [];

            elements.forEach(element => {
              if (element.role !== 'DEMO_ADMIN') {
                roles.push({
                  text: this.getRole(element.role),
                  key: element.role
                });
              }
            });

            return roles;
          })
        )
        .subscribe((roles: any[]) => {
          this.roleOptions$.next(roles);
        })
    );
  }

  getRole(r: string) {
    var label = '';
    this.translateService
      .get('organization.roles.' + r.toLowerCase())
      .subscribe((data: string) => (label = data));
    return label;
  }

  getOrganisationMembers(orgaId: string): void {
    this.subscriptions.add(
      this.auth
        .getTokenSilently$({ organization: orgaId })
        .pipe(
          switchMap(token =>
            this.apiService.getOrganisationMembers(orgaId, token)
          )
        )
        .subscribe({
          next: (result: any) => {
            const isAdmin = this.checkIfAdmin(
              result.content,
              this.currentUser.uuid
            );

            const withUniqueAdmin = this.checkIfUniqueAdmin(result.content);

            this.isAdmin$.next(isAdmin);
            this.withUniqueAdmin$.next(withUniqueAdmin);
            this.myUserId$.next(this.currentUser.uuid);
            this.getOrganisationInvitations(this.orgId, result.content);
          },
          error: error =>
            this.store.dispatch(
              organisationMembersNotLoaded({
                organisationError: error.payload
              })
            )
        })
    );
  }

  getOrganisationShops(orgaId: string): void {
    this.subscriptions.add(
      this.auth
        .getTokenSilently$({ organization: orgaId })
        .pipe(
          switchMap(token =>
            this.apiService.getOrganisationShops(orgaId, token)
          )
        )
        .subscribe((result: any) =>
          this.organisationShops$.next(result.content)
        )
    );
  }

  getOrganisationConnectedDealers(orgaId: string): void {
    this.subscriptions.add(
      this.auth
        .getTokenSilently$({ organization: orgaId })
        .pipe(
          switchMap(token =>
            this.apiService.getOrganisationConnectedDealers(orgaId, token)
          )
        )
        .subscribe((result: any) =>
          this.organisationConnectedDealers$.next(result)
        )
    );
  }

  getOrganisationDefaultDealer(orgaId: string, dealerId: string): void {
    this.store.dispatch(
      loadOrganisationDefaultDealer({
        orgaId,
        dealerId: dealerId
      })
    );
  }

  getOrganisationInvitations(orgaId: string, users: any[]): void {
    this.subscriptions.add(
      this.auth
        .getTokenSilently$({ organization: orgaId })
        .pipe(
          switchMap(token =>
            this.apiService.getOrganisationInvitations(orgaId, token)
          ),
          finalize(() => this.requestInProgress$.next(false))
        )
        .subscribe((invitations: any) => {
          const processedInvitations =
            this.markOutdatedInvitations(invitations);

          this.organisationMembers$.next([...users, ...processedInvitations]);
        })
    );
  }

  markOutdatedInvitations(invitations: any): any {
    return invitations.map((element: any) => {
      if (element.invitee) {
        const expirationDate = new Date(element.expiresAt);
        const now = new Date();

        if (now > expirationDate) {
          element['isExpired'] = true;
        }
      }

      return element;
    });
  }

  checkIfUniqueAdmin(users: any[]): boolean {
    const adminUsers = users.filter(user => user?.role === 'ADMIN');

    return adminUsers.length < 2;
  }

  checkIfAdmin(users: any[], userId: string): boolean {
    const adminUsers = users.filter(user => user?.idpUser === userId);

    return adminUsers[0] && adminUsers[0].role === 'ADMIN';
  }

  setTabs(): void {
    const tabsKeyWords = [
      'organization.overview',
      'organization.user_management',
      'organization.dealer_management'
    ];
    const tabsArray = [
      {
        title: '-',
        disabled: false,
        content: this.overview
      },
      {
        title: '-',
        disabled: false,
        content: this.usersManagement
      },
      {
        title: '-',
        disabled: false,
        content: this.dealersManagement
      }
    ];

    this.getTranslationsObs$(tabsKeyWords).subscribe((translations: any[]) => {
      for (let i = 0; i < translations.length; i++) {
        tabsArray[i].title = translations[i];
      }
    });

    this.tabs = tabsArray;
    this.currentIndex$.next(0);
    // to avoid bad initialization of currentObservers due to deeplink use case
    setTimeout(() => this.currentIndex$.next(this.currentIndex), 1500);
  }

  getTranslationsObs$(tabsKeyWords: string[]): Observable<any> {
    const obs: Observable<string>[] = [];
    tabsKeyWords.forEach(key => obs.push(this.translateService.get(key)));

    return forkJoin(obs);
  }

  goPreviousPage(): void {
    this.router.navigate(['/organisations', {}]);
  }

  reloadOrganisationDetails(currentIndex: number): void {
    this.currentIndex = currentIndex;
    this.isLoadingOrganisationDetails$.next(true);

    this.store.dispatch(loadUserOrganisationsSummary());
  }

  setAsDefaultOrganisation(): void {
    this.store.dispatch(
      setUserDefaultOrganisation({
        orgId: this.orgId ? this.orgId : '',
        userId: this.currentUser.uuid
      })
    );
  }

  updateMemberRole(input: any): void {
    this.requestInProgress$.next(true);

    this.subscriptions.add(
      this.auth
        .getTokenSilently$({ organization: this.orgId })
        .pipe(
          switchMap(token =>
            this.apiService.updateOrganisationMember(
              this.orgId,
              this.currentUser.uuid,
              {
                idpUser: input.idpUser,
                role: input.role
              },
              token
            )
          ),
          finalize(() => this.getOrganisationMembers(this.orgId))
        )
        .subscribe({
          next: () => {
            this.translateService
              .get('organization.update_user.success')
              .subscribe(text => this.displayPopup(text, ''));
          },
          error: err => {
            this.translateService
              .get('organization.update_user.fail')
              .subscribe(text => this.displayPopup(text, 'error'));
          }
        })
    );
  }

  leaveOrganisation(userId: string): void {
    userId =
      userId.includes('google') ||
      userId.includes('facebook') ||
      userId.includes('apple')
        ? 'auth0|'.concat(userId)
        : userId;

    this.requestInProgress$.next(true);

    this.subscriptions.add(
      this.authService
        .getTokenSilently$({ organization: this.orgId })
        .pipe(
          switchMap(token =>
            this.apiService.deleteOrganisationMember(this.orgId, userId, token)
          ),
          finalize(() => this.getOrganisationMembers(this.orgId))
        )
        .subscribe({
          next: () => {
            this.translateService
              .get('organization.leave_organization.success')
              .subscribe(text => this.displayPopup(text, ''));
            // redirect to org list
            this.store.dispatch(loadUserOrganisationsSummary());
            this.router.navigate(['/organisations']);
          },
          error: err => {
            this.translateService
              .get('organization.leave_organization.fail')
              .subscribe(text => this.displayPopup(text, 'error'));
          }
        })
    );
  }

  deleteOrganisationInvitation(targetId: string, isMember?: boolean): void {
    this.subscriptions.add(
      this.authService
        .getTokenSilently$({ organization: this.orgId })
        .pipe(
          switchMap(accessToken =>
            isMember
              ? this.apiService.deleteOrganisationMember(
                  this.orgId,
                  targetId,
                  accessToken
                )
              : this.apiService.deleteOrganisationInvitation(
                  this.orgId,
                  targetId,
                  accessToken
                )
          ),
          finalize(() => this.getOrganisationMembers(this.orgId))
        )
        .subscribe({
          next: () => {
            this.translateService
              .get(
                isMember
                  ? 'organization.remove_user.success'
                  : 'organization.delete_invitation.success'
              )
              .subscribe(text => this.displayPopup(text, ''));
          },
          error: err => {
            this.translateService
              .get(
                isMember
                  ? 'organization.remove_user.fail'
                  : 'organization.delete_invitation.fail'
              )
              .subscribe(text => this.displayPopup(text, 'error'));
          }
        })
    );
  }

  inviteUserToOrganisation(payload: InvitationCreateModel): void {
    this.requestInProgress$.next(true);

    this.subscriptions.add(
      this.authService
        .getTokenSilently$({ organization: this.orgId })
        .pipe(
          switchMap(token =>
            this.apiService.createInvitation(this.orgId, payload, token)
          ),
          finalize(() => this.getOrganisationMembers(this.orgId))
        )
        .subscribe({
          next: () => {
            this.translateService
              .get('organization.user_invitation.success')
              .subscribe(text => this.displayPopup(text, ''));
          },
          error: err => {
            this.translateService
              .get('organization.user_invitation.fail')
              .subscribe(text => this.displayPopup(text, 'error'));
          }
        })
    );
  }

  displayPopup(text: string, className?: string): void {
    this.snackBar.open(text, '', {
      duration: 5000,
      panelClass: className ? className : ''
    });
  }

  disconnectDealers(
    targets: OrganisationDealerModel[] | undefined | null
  ): void {
    const subscriptionsArray: any[] = [];
    this.organisationConnectedDealers$.next(undefined);

    targets?.forEach(element =>
      subscriptionsArray.push(
        this.authService
          .getTokenSilently$({ organization: this.orgId })
          .pipe(
            switchMap(accessToken =>
              this.apiService.disconnectDealer(
                this.orgId,
                element.dealerId,
                accessToken
              )
            )
          )
      )
    );

    if (subscriptionsArray.length) {
      this.subscriptions.add(
        forkJoin(subscriptionsArray)
          .pipe(
            finalize(() => this.getOrganisationConnectedDealers(this.orgId))
          )
          .subscribe({
            next: value => {
              // Disconnect Dealer from thr organisation
              this.translateService
                .get('organization.remove_dealer.success')
                .subscribe((text: string) => {
                  this.displayPopup(text, '');
                });
            },
            error: err => {
              this.translateService
                .get('organization.remove_dealer.fail')
                .subscribe((text: string) => {
                  this.displayPopup(text, 'error');
                });
            }
          })
      );
    }
  }

  setNewOrganisationDealer(props: any): void {
    let info: IDealerData;

    this.subscriptions.add(
      this.apiService
        .getDealer(props.dealerId, this.translateService.currentLang)
        .pipe(
          switchMap((dealerData: IDealerData) => {
            info = dealerData;
            return this.auth.getTokenSilently$({
              organization: props.org?.auth0Id
            });
          }),
          switchMap((token: string) => {
            const { deliveryPrograms, dealerLocators, ...dealerPayload } = info;

            return this.apiService.updateOrganisationDealer(
              props.org?.auth0Id,
              dealerPayload,
              token
            );
          })
        )
        .subscribe({
          next: (result: any) =>
            this.store.dispatch(
              loadOrganisationDefaultDealer({
                orgaId: props.org?.auth0Id,
                dealerId: result?.dealerId
              })
            ),

          error: (): any =>
            this.translateService
              .get('overview.connection_error')
              .subscribe(text => this.displayPopup(text, 'error'))
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
