import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import {
  allAuthActions,
  selectIsLoggedIn,
  selectUserAvatarUrl,
  selectUserDetails
} from './shared/state/user';
import { allGlobalActions } from './shared/state/global';
import { ApiService } from './shared/services/api.service';
import { DOCUMENT } from '@angular/common';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  BehaviorSubject,
  distinctUntilChanged,
  forkJoin,
  Observable,
  Subscription,
  switchMap,
  take,
  tap
} from 'rxjs';
import { NavigationItem } from '@claas/claas-navigation-components';
import {
  ButtonClass,
  ButtonSize,
  Icon,
  IconFontSize,
  IconSize
} from '@claas/claas-form-components';
import { AuthService } from './shared/services/auth.service';
import {
  CircularProgressDiameter,
  DividerOrientation,
  ProgressColor
} from '@claas/claas-layout-components';
import {
  loadMarketingPermissions,
  loadUserCoordinates
} from './shared/state/user-coordinates/user-coordinates.actions';
import { NavigationEnd, Router } from '@angular/router';
import {
  loadOrganisationRegistrationSteps,
  loadUserOrganisationsSummary
} from './shared/state/organisations';
import {
  selectMarketingPermissions,
  selectUserCoordinates
} from './shared/state/user-coordinates/user-coordinates.selectors';
import { filter } from 'rxjs/operators';
import { UserAddress } from './shared/models/user-address.model';
import * as UserActions from './shared/state/user/user.actions';
import { IUser } from 'cde-fe-organization-registration-dialog';
import { OrganizationInvitation } from './shared/models/organisation-invitation.model';
import {
  DialogWindowComponent,
  ResponseDataFromDialog
} from './shared/components/dialog-window/dialog-window.component';
import { UserUpdateRequest } from './shared/models/user-update-request.model';
import { Address } from './shared/models/address.model';
import { MarketingPermission } from './shared/models/marketing-permission.model';
import { getFeatureToggles } from './shared/state/feature-toggle/feature-toggle.actions';
import { OrganizationAdminInvitationsContainer } from './shared/models/organisation-admin-invitation-container.model';
import { UserOrganisationSummary } from './shared/state/organisations/organisations.state';
import { selectOrganisationsSummary } from './shared/state/organisations/organisations.selectors';
import { OrganisationMember } from './shared/models/organisation-user.model';

export const FALLBACK_LANGUAGE = 'en-gb';
export let isHandset = false;
export let isTablet = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private outsideClickListener: any;

  marketingPermissionsLink = {
    label: 'navigation.preference_center',
    route: '/preference-center',
    activeRoutes: ['/preference-center'],
    iconId: '013435',
    iconNamespace: 'claas-id'
  };

  navigationItems: NavigationItem[] = [];

  bottomMenuItems: NavigationItem[] = [
    {
      label: 'footer.help',
      action: this.printStuff,
      iconId: '006200',
      iconNamespace: 'claas-id',
      size: 24
    },
    {
      label: 'footer.notification',
      action: this.printStuff,
      iconId: '648468',
      iconNamespace: 'claas-id'
    },
    {
      label: 'footer.applications',
      action: this.printStuff,
      iconId: '001450',
      iconNamespace: 'claas-id'
    }
  ];

  // translate strings
  logout = 'Logout';

  // Variables
  smallButtonSize = ButtonSize.SMALL;
  smallIconSize = IconSize.SMALL;
  smallIconFontSize = IconFontSize.SMALL;
  secondaryOutlineButtonClass = ButtonClass.SECONDARY_OUTLINE;
  verticalDivider = DividerOrientation.VERTICAL;
  secondaryColor = ProgressColor.SECONDARY;
  largeSize = CircularProgressDiameter.LARGE;

  // User Profile Variables
  salutationValue = '';
  language = '';
  country = '';
  profileJson: any;
  avatarUrl = '/assets/images/_Avatar_.png';

  menuExpanded = true;
  avatarMenu = false;

  isFirstTime = true;
  isLoggedIn = false;

  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small])
    .pipe(
      tap(value => console.log(value)),
      distinctUntilChanged()
    );

  menuIconOpen: Icon = {
    iconId: '648425',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 24
  };

  menuIconClosed: Icon = {
    iconId: '648420',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 24
  };

  helpIcon: Icon = {
    iconId: '006200',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 24
  };

  notificationIcon: Icon = {
    iconId: '648468',
    iconStyle: 'bold',
    namespace: 'claas-id',
    size: 24
  };

  logoutIcon: Icon = {
    iconId: '659471',
    iconStyle: 'bold',
    namespace: 'claas-id'
  };
  isPublicPage = false;

  get isHandset() {
    return isHandset;
  }

  get isTablet() {
    return isTablet;
  }

  salutation$ = new BehaviorSubject('');
  title$ = new BehaviorSubject('');
  givenName$ = new BehaviorSubject('');
  familyName$ = new BehaviorSubject('');
  email$ = new BehaviorSubject('');

  user: IUser | undefined;
  organisationInvitation: OrganizationInvitation | undefined;
  orgAdminInvitations: OrganizationAdminInvitationsContainer[] = [];
  private subscriptions = new Subscription();
  userUpdateRequest: UserUpdateRequest | undefined;
  marketingInvitation: MarketingPermission[] = [];

  constructor(
    private translate: TranslateService,
    private store: Store,
    private apiService: ApiService,
    public auth0: Auth0Service,
    public authService: AuthService,
    @Inject(DOCUMENT) public document: Document,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    public dialog: MatDialog
  ) {
    this.store.dispatch(allAuthActions.checkLogin());
    this.navigationItems = this.getBasicNavigationItems();
    window.localStorage.removeItem('currentOrganisationId');
  }

  ngOnInit() {
    // get browser language
    let browser_Lang = navigator.language;
    const browser_Country = browser_Lang.substring(3, 5);
    browser_Lang = browser_Lang.substring(0, 2);
    if (browser_Lang === 'en') {
      if (browser_Country.toLowerCase() === 'us') {
        browser_Lang = 'en-us';
      } else {
        browser_Lang = 'en-gb';
      }
    }

    this.store
      .select(selectUserCoordinates)
      .pipe(filter((user: any) => !!user))
      .subscribe((user: UserAddress) =>
        this.store.dispatch(
          loadMarketingPermissions({
            claasId: user.user_id ? user.user_id : ''
          })
        )
      );

    this.store.select(selectMarketingPermissions).subscribe(permissions => {
      if (permissions && permissions.length) {
        this.navigationItems = this.getBasicNavigationItems();
        this.navigationItems.push(this.marketingPermissionsLink);
      } else {
        this.navigationItems = this.navigationItems.filter(
          item => item.route !== '/preference-center'
        );
      }

      if (environment.general.languages.indexOf(browser_Lang) >= 0) {
        this.i18nInit(browser_Lang);
      } else {
        this.i18nInit(FALLBACK_LANGUAGE);
      }
    });

    this.store.dispatch(allGlobalActions.rehydrateState());

    this.breakpoint$.subscribe(() => this.breakpointChanged());

    this.store
      .select(selectUserDetails)
      .pipe(filter(res => !!res && !!res?.user_metadata))
      .subscribe((res: any) => {
        if (res.user_metadata['given_name']) {
          this.givenName$.next(res.user_metadata['given_name']);
        }

        if (res.user_metadata['family_name']) {
          this.familyName$.next(res.user_metadata['family_name']);
        }

        if (res.user_metadata['salutation']) {
          this.salutationValue = res.user_metadata['salutation'];
          this.salutation$.next(res.user_metadata['salutation']);
        }

        if (res.user_metadata['title']) {
          this.title$.next(res.user_metadata['title']);
        }

        if (res.user_metadata['language']) {
          this.language = res.user_metadata['language'].toLowerCase();
          this.changeLanguage(this.language);
        }

        if (res.user_metadata['country']) {
          this.country = res.user_metadata['country'].toLowerCase();
        }

        if (res['email']) {
          this.email$.next(res['email']);
        }
      });

    this.store.select(selectUserAvatarUrl).subscribe(url => {
      if (url) {
        this.avatarUrl = url;
      }
    });

    this.store.select(selectIsLoggedIn).subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;

      if (isLoggedIn) {
        this.store.dispatch(loadUserCoordinates());
        this.store.dispatch(loadOrganisationRegistrationSteps());
        this.store.dispatch(loadUserOrganisationsSummary());
        this.store.dispatch(getFeatureToggles());
        this.store.dispatch(UserActions.allAuthActions.loadUserAvatarUrl());
      }
    });

    this.trackRouting();

    this.subscriptions.add(
      this.store
        .select(selectUserDetails)
        .pipe(
          filter((user: any) => !!user),
          tap((user: IUser) => {
            this.user = user;

            this.getUserUpdateRequest();
            this.getInvitations();
            this.getMarketingInvitations();

            this.store
              .select(selectOrganisationsSummary)
              .pipe(
                filter(result => !!result),
                take(1)
              )
              .subscribe((orgs: UserOrganisationSummary[] | null) => {
                if (orgs) {
                  this.getOrganizationUpdatesAndInvitations(orgs);
                }
              });
          })
        )
        .subscribe()
    );
  }

  getBasicNavigationItems(): NavigationItem[] {
    return [
      {
        label: 'navigation.personal_data',
        route: '/personal-data',
        activeRoutes: ['/personal-data'],
        iconId: '001608',
        iconNamespace: 'claas-id',
        size: 24
      },
      {
        label: 'navigation.account_settings',
        route: '/user-settings',
        activeRoutes: ['/user-settings'],
        iconId: '648466',
        iconNamespace: 'claas-id'
      },
      {
        label: 'navigation.organization_settings',
        route: '/organisations',
        activeRoutes: ['/organisations'],
        iconId: '018412',
        iconNamespace: 'claas-id'
      },
      {
        label: 'navigation.connected_apps',
        route: '/applications',
        activeRoutes: ['/applications'],
        iconId: '017745',
        iconNamespace: 'claas-id'
      }
    ];
  }

  private trackRouting(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.isHandset || this.isTablet) {
          this.menuExpanded = false;
        }

        this.isPublicPage = !this.checkIfAuthIsNeeded(event);
      }
    });
  }

  private i18nInit(lang: string) {
    this.translate.setDefaultLang(FALLBACK_LANGUAGE);
    this.store
      .select(selectUserCoordinates)
      .pipe(filter((user: any) => !!user))
      .subscribe((res: any) => {
        lang = res.language;
      });
    this.translate.use(lang);
    this.triggerTranslations();
  }

  private triggerTranslations() {
    this.translate.get('navigation.personal_data').subscribe(data => {
      this.navigationItems[0].label = data;
    });
    this.translate.get('navigation.account_settings').subscribe(data => {
      this.navigationItems[1].label = data;
    });
    this.translate.get('navigation.organization_settings').subscribe(data => {
      this.navigationItems[2].label = data;
    });
    this.translate.get('navigation.connected_apps').subscribe(data => {
      this.navigationItems[3].label = data;
    });

    if (this.navigationItems[4]) {
      this.translate.get('navigation.preference_center').subscribe(data => {
        this.navigationItems[4].label = data;
      });
    }

    this.translate.get('generic.logout').subscribe(data => {
      this.logout = data;
    });
    this.translate.get('footer.help').subscribe(data => {
      this.bottomMenuItems.at(0)!.label = data;
    });
    this.translate.get('footer.notification').subscribe(data => {
      this.bottomMenuItems.at(1)!.label = data;
    });
    this.translate.get('footer.applications').subscribe(data => {
      this.bottomMenuItems.at(2)!.label = data;
    });
  }

  private breakpointChanged() {
    if (
      this.breakpointObserver.isMatched(
        '(max-width: 599.98px) and (orientation: portrait), (max-width: 959.98px) and (orientation: landscape)'
      )
    ) {
      console.log('handset');
      isHandset = true;
      isTablet = false;
    } else if (
      this.breakpointObserver.isMatched(
        '(min-width: 600px) and (max-width: 1023.98px) and (orientation: portrait), ' +
          '(min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)'
      )
    ) {
      console.log('tablet');
      isHandset = false;
      isTablet = true;
      this.menuExpanded = false;
    } else if (
      this.breakpointObserver.isMatched(
        '(min-width: 1024px) and (orientation: portrait), (min-width: 1280px) and (orientation: landscape)'
      )
    ) {
      console.log('web');
      isHandset = false;
      isTablet = false;
      this.menuExpanded = this.isFirstTime;
      this.isFirstTime = false;
    }
  }

  logoutUser(): void {
    this.auth0
      .logout({ logoutParams: { returnTo: document.location.origin } })
      .subscribe(result => {
        this.store.dispatch(allAuthActions.logoutComplete());
      });
  }

  changeLanguage(lang: string) {
    if (environment.general.languages.includes(lang.toLowerCase())) {
      this.translate.use(lang);
    } else {
      this.translate.use(FALLBACK_LANGUAGE);
    }

    this.triggerTranslations();
  }

  toggleMenu() {
    this.menuExpanded = !this.menuExpanded;
  }

  toggleAvatarMenu() {
    this.avatarMenu = !this.avatarMenu;

    if (this.avatarMenu) {
      this.startListeningForOutsideClicks();
    } else {
      this.stopListeningForOutsideClicks();
    }
  }

  startListeningForOutsideClicks(): void {
    this.outsideClickListener = this.renderer.listen(
      'document',
      'click',
      (event: MouseEvent) => {
        const clickedElement = event.target as HTMLElement;
        const classNames: string = clickedElement.className;

        const clickedInside = classNames.includes('avatar-menu-element');

        if (!clickedInside) {
          this.avatarMenu = false;
          this.stopListeningForOutsideClicks();
        }
      }
    );
  }

  stopListeningForOutsideClicks(): void {
    if (this.outsideClickListener) {
      this.avatarMenu = false;
      this.outsideClickListener();
      this.outsideClickListener = null;
    }
  }

  printStuff() {
    console.log('Stuff');
  }

  redirectToInfo(): void {
    if (environment.info_url) {
      var lang = 'en';
      lang = this.translate.currentLang;
      if (lang == 'en-gb' || lang == 'en-us') {
        lang = 'en';
      }
      window.open(
        environment.info_url + 'page/' + lang + '/web/id/claas-id',
        '_blank'
      );
    }
  }

  openDialog(context: string, invitation?: OrganizationInvitation): void {
    let config: any;

    switch (context) {
      case 'user-invitation':
        config = {
          data: {
            context,
            buttonCancel: 'generic.cancel',
            buttonSave: 'generic.confirm',
            organisationInvitation: this.organisationInvitation
          }
        };
        break;

      case 'user-update':
        config = {
          data: {
            context,
            userUpdateRequest: this.userUpdateRequest,
            buttonCancel: 'generic.cancel',
            buttonSave: 'generic.confirm'
          }
        };
        break;

      case 'marketing-permission-update':
        config = {
          data: {
            context,
            buttonCancel: 'generic.cancel',
            buttonSave: 'generic.confirm',
            userId: this.user?.uuid,
            userCountry: this.user?.user_metadata.country,
            marketingInvitation: this.marketingInvitation,
            largeDisplay: true
          }
        };
        break;
    }

    config.maxWidth = this.isHandset ? '100vw' : '80vw';

    const dialogRef = this.dialog.open(DialogWindowComponent, config);

    this.subscriptions.add(
      dialogRef
        .afterClosed()
        .pipe(
          tap((answer: ResponseDataFromDialog) => {
            if (answer) {
              switch (answer.context) {
                case 'user-update':
                  // reload user data
                  this.store.dispatch(loadUserCoordinates());
                  break;

                case 'marketing-permission-update':
                  // reload mp data
                  this.store.dispatch(
                    loadMarketingPermissions({
                      claasId: this.user?.uuid ? this.user?.uuid : ''
                    })
                  );
                  break;

                case 'user-invitation':
                  this.store.dispatch(loadUserOrganisationsSummary());
                  break;
              }
            }
          })
        )
        .subscribe()
    );
  }

  openMultiDialog(entities: any[]): void {
    let config: any;
    const entity = entities.splice(0, 1)[0];

    switch (entity.context) {
      case 'admin-invitation':
        config = {
          data: {
            context: entity.context,
            buttonCancel: 'generic.cancel',
            buttonSave: 'generic.confirm',
            organisationInvitation: entity
          }
        };
        break;

      case 'org-update-request':
        config = {
          data: {
            context: entity.context,
            buttonCancel: 'generic.cancel',
            buttonSave: 'generic.confirm',
            orgUpdateRequest: entity.orgUpdateRequest
          }
        };
        break;

      case 'org-deletion-request':
        const organisationData = {
          dealerId: entity.dealerId ? entity.dealerId : '',
          dealerName: entity.dealerName ? entity.dealerName : '',
          orgId: entity.orgId ? entity.orgId : '',
          orgName: entity.orgName ? entity.orgName : ''
        };

        config = {
          data: {
            context: entity.context,
            organisationData,
            buttonCancel: 'generic.cancel',
            buttonSave: 'generic.confirm'
          }
        };
        break;
    }

    config.maxWidth = this.isHandset ? '100vw' : '80vw';

    const dialogRef = this.dialog.open(DialogWindowComponent, config);

    this.subscriptions.add(
      dialogRef
        .afterClosed()
        .pipe(
          tap((answer: ResponseDataFromDialog) => {
            if (entities.length) {
              this.openMultiDialog(entities);
            } else {
              this.store.dispatch(loadUserOrganisationsSummary());
            }
          })
        )
        .subscribe()
    );
  }

  getInvitations() {
    if (this.user) {
      this.subscriptions.add(
        this.apiService.getOrganisationInvitation(this.user.sub).subscribe({
          next: (res: any) => {
            this.organisationInvitation = res;
            if (
              this.organisationInvitation !== null &&
              this.dialog.openDialogs.length === 0
            ) {
              this.openDialog('user-invitation');
            }
          },
          error: err => {
            console.log(err);
          }
        })
      );
    }
  }

  getUserUpdateRequest(): void {
    this.subscriptions.add(
      this.apiService.getUserUpdateRequest().subscribe((result: any) => {
        this.userUpdateRequest = result;

        if (this.userUpdateRequest && this.dialog.openDialogs.length === 0) {
          this.openDialog('user-update');
        }
      })
    );
  }

  getMarketingInvitations() {
    if (this.user?.uuid) {
      this.subscriptions.add(
        this.apiService
          .getMarketingInvitationsById(this.user?.uuid)
          .subscribe(invitations => {
            if (invitations) {
              this.marketingInvitation = invitations;

              for (const inv of this.marketingInvitation) {
                // Check if address object existing or create it
                if (inv.channels && !inv.channels.address) {
                  inv.channels.address = new Address();
                }
                // Check if phone_number object existing or create it
                if (inv.channels && !inv.channels.phone_number) {
                  const phoneNumber = {
                    type: '',
                    number: '',
                    country_prefix: ''
                  };
                  inv.channels.phone_number = phoneNumber;
                }
              }

              if (
                this.marketingInvitation.length > 0 &&
                this.dialog.openDialogs.length === 0
              ) {
                this.openDialog('marketing-permission-update');
              }
            }
          })
      );
    }
  }

  getOrganizationUpdatesAndInvitations(orgs: UserOrganisationSummary[]) {
    const obs: Observable<any>[] = [];

    obs.push(this.apiService.getOrganisationAdminInvitations());
    obs.push(this.apiService.getOrganisationDeletionRequests());
    obs.push(this.apiService.getOrganisationUpdateRequests());

    forkJoin(obs)
      .pipe(filter(result => !!result))
      .subscribe(([invitations, deletions, updates]) => {
        let entities: any[] = [];

        // Part 1: handle the admin invitations
        this.orgAdminInvitations = invitations.filter(
          (element: OrganizationAdminInvitationsContainer) =>
            element['organizationAdminInvitations'].length
        );

        this.orgAdminInvitations.forEach(
          (container: OrganizationAdminInvitationsContainer) => {
            if (container.organizationAdminInvitations) {
              container.organizationAdminInvitations =
                container.organizationAdminInvitations.map(
                  (invitation: any) => ({
                    ...invitation,
                    context: 'admin-invitation'
                  })
                );

              entities = entities.concat(
                container.organizationAdminInvitations
              );
            }
          }
        );

        // Part 2: handle the update requests
        let updateRequestsArray: any[] = this.prepareOrganisationsUpdateArray(
          updates,
          orgs
        );

        if (updateRequestsArray.length) {
          updateRequestsArray = updateRequestsArray.map((request: any) => ({
            ...request,
            context: 'org-update-request'
          }));

          entities = entities.concat(updateRequestsArray);
        }

        // Part 3: handle the deletion requests where each element of the array is an object
        let organisationsDataArray: any[] =
          this.prepareOrganisationsDeletionArray(deletions, orgs);

        if (organisationsDataArray.length) {
          if (this.user?.user_metadata.language) {
            const language = this.user?.user_metadata.language;
            const obsArray: Observable<any>[] = [];

            organisationsDataArray.forEach((el: any) => {
              obsArray.push(
                this.authService
                  .getTokenSilently$()
                  .pipe(
                    switchMap(token =>
                      this.apiService.getDealer(el.dealerId, language, token)
                    )
                  )
              );
            });

            forkJoin(obsArray).subscribe((res: any) => {
              res.forEach((dealer: any, index: number) => {
                organisationsDataArray[index].dealerName = dealer.name;
              });

              organisationsDataArray = organisationsDataArray.map(deletion => ({
                ...deletion,
                context: 'org-deletion-request'
              }));

              entities = entities.concat(organisationsDataArray);
              this.openMultiDialog(entities);
            });
          } else {
            this.openMultiDialog(entities);
          }
        } else {
          this.openMultiDialog(entities);
        }
      });
  }
  prepareOrganisationsUpdateArray(
    updates: any,
    orgs: UserOrganisationSummary[]
  ): any[] {
    const outputArray: any[] = [];

    updates.forEach((update: any) => {
      const index = orgs.findIndex(org => org['auth0Id'] === update.auth0Id);

      if (index || index === 0) {
        const myUser = orgs[index]['members'].find(
          (member: OrganisationMember) => member.userId === this.user?.uuid
        );

        if (myUser && myUser.role === 'ADMIN') {
          const updateRequest = {
            orgUpdateRequest: update
          };

          outputArray.push(updateRequest);
        }
      }
    });

    return outputArray;
  }

  prepareOrganisationsDeletionArray(
    deletions: any[],
    orgs: UserOrganisationSummary[]
  ): any[] {
    const outputArray: any[] = [];

    deletions.forEach((deletion: any) => {
      const index = orgs.findIndex(org => org['auth0Id'] === deletion.auth0Id);

      if (index || index === 0) {
        const myUser = orgs[index]['members'].find(
          (member: OrganisationMember) => member.userId === this.user?.uuid
        );

        if (myUser && myUser.role === 'ADMIN') {
          const organisationData = {
            dealerId: deletion.dealerSapRefId,
            orgId: deletion.auth0Id,
            orgName: orgs[index]['name']
          };

          outputArray.push(organisationData);
        }
      }
    });

    return outputArray;
  }

  checkIfAuthIsNeeded(event: NavigationEnd) {
    return (
      !event.url.includes('type=doi') &&
      !event.url.includes('type=new') &&
      !event.url.includes('type=update') &&
      !event.url.includes('org-selection') &&
      !event.url.includes('complete') &&
      !event.url.includes('continue') &&
      !event.url.includes('info') &&
      !event.url.includes('submit')
    );
  }

  gotoConnect() {
    window.open(environment.base_urls.cc_3_base_url + '/dashboard', '_self');
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
